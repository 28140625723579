
import { Component, Vue } from "vue-property-decorator";
import TigerSchoolBanner from "@/components/Academy/Utility/TigerSchoolBanner.vue";
@Component({
  components: {
    TigerSchoolBanner,
  },
})
export default class EnquireTraining extends Vue {
  bannerOption = {
    back: "返回師虎學院",
    title: "企業培訓基地",
    btnText: "企業合作",
    // linkUrl: "/Academy/EnterpriseEnquire",
    linkUrl:
      "https://docs.google.com/forms/d/e/1FAIpQLSdEtMMu8a2Qm_lOajFOjDSNfcNiVJRZI7NKGhjJ3xape_spaw/viewform?usp=sf_link",
    cssProps: {
      backgroundImage: `url(${require("@/assets/banner/training.jpg")})`,
    },
  };
}
